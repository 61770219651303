import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// Router dom
import { Helmet } from 'react-helmet'
import LogoIcon from '../../assets/logos/logo.png'

// Components
import RestaurantCard from '../../components/RestaurantCard'
import SelectFoodCard from '../../components/SelectFoodCard'
// Hooks
import { useIsAuthenticated } from '../../hooks'

// Redux
import useForceLogoutUnverifiedUser from '../../hooks/useForceLogout'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setLogo } from '../../store/slices/globals'
import {
  resetConfirmPaymentState,
  resetExecutePaymentState,
  resetPaymentState,
} from '../../store/slices/payments'
import { resetSubscriptionState } from '../../store/slices/subscriptions'
// Styles

// import { resetSelectedFoods } from '../../store/restaurant'
import { getRestaurants } from '../../store/slices/restaurants'

import { HorizontalScrollBox } from '../../components/SelectFoodCard/styles'
import { Layout } from '../../layout/Layout2'
import { actions } from '../../store/slices/foodselection'
import { actions as restaurantActions } from '../../store/slices/restaurants'
import { ActiveSubscription } from '../../types/accounts'

const TabPan = () => {
  const { t } = useTranslation()
  const { isAuthenticated } = useIsAuthenticated()
  const dispatch = useAppDispatch()
  const { userData } = useAppSelector((state) => state.accounts)
  const { restaurants } = useAppSelector((state) => state.restaurants)
  const subscribedUsers = userData?.customer?.active_subscriptions

  useEffect(() => {
    dispatch(actions.resetAllStates())
    dispatch(restaurantActions.setPlanDetail())
  }, [])
  return (
    <Box>
      {isAuthenticated && (
        <HorizontalScrollBox>
          <Grid container spacing={{ xs: 2 }}>
            {subscribedUsers &&
              subscribedUsers.map((subscribedUser: ActiveSubscription) => (
                <Grid item xs={12} sm={6} md={4} key={subscribedUser.id}>
                  <SelectFoodCard
                    to={`/food-selection/${subscribedUser.id}`}
                    subscribedUser={subscribedUser}
                  />
                </Grid>
              ))}
          </Grid>
        </HorizontalScrollBox>
      )}

      <Typography
        variant="h1"
        sx={(theme) => ({
          color: theme.palette.text.primary,
          textTransform: theme.typography.h1.textTransform,
          mt: '9px',
          mb: '8px',
          lineHeight: 'normal',
        })}
      >
        {t('RESTAURANTS')}
      </Typography>

      <Typography
        variant="h3"
        sx={(theme) => ({
          color: theme.palette.text.disabled,
          mb: '24px',
          fontWeight: 400,
        })}
      >
        {t('RESTAURANTS_DESCRIPTION')}
      </Typography>

      <Grid container spacing={{ xs: 2 }}>
        {restaurants &&
          restaurants.map((restaurant) => (
            <Grid item xs={12} sm={6} md={4} key={restaurant.id}>
              <RestaurantCard {...restaurant} />
            </Grid>
          ))}
      </Grid>
    </Box>
  )
}

const Home: React.FC = () => {
  useForceLogoutUnverifiedUser()
  const { t } = useTranslation()
  const { userData } = useAppSelector((state) => state.accounts)

  const dispatch = useAppDispatch()
  const { isLoading, restaurants } = useAppSelector((state) => state.restaurants)

  useEffect(() => {
    const userDataFromStorage = JSON.parse(localStorage.getItem('user') || '{}')
    const token = userData?.token?.access || userDataFromStorage?.token?.access

    dispatch(getRestaurants(token || null))
  }, [userData?.token?.access])

  useEffect(() => {
    dispatch(setLogo(LogoIcon))
    dispatch(resetPaymentState())
    dispatch(resetSubscriptionState())
    dispatch(resetExecutePaymentState())
    dispatch(resetConfirmPaymentState())

    localStorage.removeItem('executeRequestPaymentData')
    localStorage.removeItem('checkoutData')

    // clear checkout page view id
    sessionStorage.removeItem('checkoutPageViewId')
  }, [])

  const { i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'

  return (
    <Layout title={t('HOME_RESTAURANTS')} isLoading={isLoading}>
      <Helmet>
        <meta
          name="description"
          content={
            isArabic
              ? 'اشتراكات | إبحث عن المطعم المناسب وقم بالاشتراك للحصول على وجبات صحية على حسب إختيارك | اشتراكات للمطاعم الصحية'
              : 'Eshtrakaat for Subscriptions Restaurants | Find the right restaurant and subscribe to get healthy meals according to your choice'
          }
        />
        <title>
          {isArabic
            ? 'اشتراكات للمطاعم الصحية | اختيار المطعم'
            : 'Eshtrakaat for Subscriptions Restaurants | Choose a Restaurant'}
        </title>
      </Helmet>
      <TabPan />

      { restaurants.length != 0 &&
        <Box sx={{ textAlign: "center", p: 2 }}>
          جميع الحقوق محفوظة 2024: شركة اشتراكات بوكس لوكيل بالعمولة
          والاتجار بالعمولة
        </Box>
      }
    </Layout>
  )
}

export default Home
