// src/store/utmSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the shape of the UTM state
interface UtmState {
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
}

// Define the initial state using the UtmState interface
const initialState: UtmState = {
  utm_source: null,
  utm_medium: null,
  utm_campaign: null,
};

// Create the UTM slice
const utmSlice = createSlice({
  name: 'utm',
  initialState,
  reducers: {
    setUtmParameters: (
      state,
      action: PayloadAction<{ utm_source: string | null; utm_medium: string | null; utm_campaign: string | null }>
    ) => {
      state.utm_source = action.payload.utm_source;
      state.utm_medium = action.payload.utm_medium;
      state.utm_campaign = action.payload.utm_campaign;
    },
    clearUtmParameters: (state) => {
      state.utm_source = null;
      state.utm_medium = null;
      state.utm_campaign = null;
    },
  },
});

// Export actions and reducer
export const { setUtmParameters, clearUtmParameters } = utmSlice.actions;
export default utmSlice.reducer;
