import { createTheme, useTheme } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ar, enUS } from 'date-fns/locale'
import { useTranslation } from 'react-i18next'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'
import { store } from './store'

const Localization: React.FC = () => {
  const { i18n, t } = useTranslation()
  // English translations
  const enLocalization = { cancelButtonLabel: t('CANCEL'), okButtonLabel: t('OK') }

  // Arabic translations
  const arLocalization = { cancelButtonLabel: t('CANCEL'), okButtonLabel: t('OK') }

  

  const localization = i18n.language === 'ar' ? arLocalization : enLocalization

  document.body.dir = i18n.dir()
  let theme = useTheme()
  const updateTheme = createTheme({
    ...theme,
    palette:
    {
      background: {
        default: '#f5f5f5' // Step 2: Set your desired color here
      }
    }, direction: i18n.dir()
  })
  theme = updateTheme

  if (i18n.language === 'en-US' || i18n.language === 'en-GB') {
    const updateTheme = createTheme({ ...theme, direction: i18n.dir() })
    theme = updateTheme
    // Set the key i18nextLng to ar, to prevent i18next-browser-languagedetector
    localStorage.setItem('i18nextLng', 'ar')
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} localeText={localization} adapterLocale={i18n.language === 'ar' ? ar : enUS}
    >
      <Provider store={store}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </Provider>
    </LocalizationProvider>
  )
}

export default Localization
