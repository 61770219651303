import { LoadingButton } from '@mui/lab'
import { Container, Typography } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as SubscriptionSuccessIcon } from '../../assets/icons/subscription_success.svg'
import { DrawerHeading } from '../../components/CustomSwipeableDrawer'
import { Layout } from '../../layout/Layout2'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { confirmPayment } from '../../store/slices/payments'
import { PaymentConfirmationRequestData } from '../../types/payments'
import { localStorageGet } from '../../utils'

const ListText = ({ children }: { children: React.ReactNode }) => (
  <Typography
    align="left"
    sx={{
      color: '#556885',
      textAlign: 'left',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      marginBottom: '0px', // Default margin bottom
      marginTop: '06px', // Default margin top
    }}
  >
    {' '}
    {children}
  </Typography>
)

const PaymentSuccess: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()

  const isModeArabic = i18n.language === 'ar'

  const { userData } = useAppSelector((state) => state.accounts)
  const { paymentConfirmationLoading, paymentConfirmationResponse } =
    useAppSelector((state) => state.payments)

  const getQueryParam = (params: string[]): string | null => {
    const currentUrl = window.location.href
    const searchParams = new URLSearchParams(new URL(currentUrl).search)
    for (const param of params) {
      const value = searchParams.get(param)
      if (value) {
        return value
      }
    }
    return null
  }

  const getPaymentId = useCallback(
    () => getQueryParam(['payment_id', 'paymentId']),
    []
  )
  const getTrackId = useCallback(() => getQueryParam(['track_id']), [])

  const paymentID: string | null = getPaymentId()
  const trackID: string | null = getTrackId()

  const params: PaymentConfirmationRequestData = {
    payment_id: paymentID,
    track_id: trackID as string,
  }

  useEffect(() => {
    if (!userData || !paymentID) return

    dispatch(confirmPayment(userData?.token?.access as string, params))

    if (window.dataLayer.find((item) => item.id === paymentID)) return

    const paymentData = localStorageGet('executeRequestPaymentData')

    window.dataLayer.push({
      id: paymentID,
      name: userData?.name,
      phone: userData?.phone_number,
      price:
        paymentData?.package?.discounted_price || paymentData?.package?.price,
      currency: 'KWD',
      plan_id: paymentData?.plan?.plan_id,
      success: true,
      environment: process.env.REACT_APP_ENVIRONMENT,
    })
  }, [paymentID, userData]) // loginSuccess is not added here, as it will cause infinite loop

  const handleButtonClick = () => {
    if (
      paymentConfirmationResponse?.transaction_status === 'Succss' &&
      userData?.token?.access &&
      userData?.customer?.active_subscriptions
    ) {
      const subscription_id = paymentConfirmationResponse.subscription_id
      navigate(
        paymentConfirmationResponse?.transaction_status === 'Succss'
          ? `/food-selection/${subscription_id}`
          : '/'
      )
    }
  }

  const backToHome = () => {
    navigate('/')
  }

  useEffect(() => {
    // Block back history
    window.history.pushState(null, '', window.location.href)
  }, [])

  return (
    <Layout
      title={t('SUCCESS_PAGE.TITLE')}
      isLoading={paymentConfirmationLoading}
    >
      <Container
        sx={(theme) => ({
          padding: '16px 0px 20px 0px',
          flexShrink: 0,
          overflow: 'hidden',
          backgroundColor: theme.palette.background.default,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          textAlign: 'center',
          direction: isModeArabic ? 'rtl' : 'ltr',
          minHeight: '88vh',
        })}
      >
        <SubscriptionSuccessIcon style={{ height: '200px' }} />

        <DrawerHeading marginBottom="3px" marginTop="10px">
          {t('SUCCESS_PAGE.HEADING')}
        </DrawerHeading>

        <ul dir={i18n.dir()} style={{ color: '#556885' }}>
          <li>
            {' '}
            <ListText>{t('SUBSCRIPTION_MESSAGE_ONE')}</ListText>{' '}
          </li>

          <li>
            {' '}
            <ListText>{t('SUBSCRIPTION_MESSAGE_TWO')}</ListText>{' '}
          </li>

          <li>
            <ListText>{t('SUBSCRIPTION_MESSAGE_THREE')}</ListText>{' '}
          </li>

          <li>
            <ListText>{t('SUBSCRIPTION_MESSAGE_FOUR')}</ListText>{' '}
          </li>
        </ul>

        <div>
          <LoadingButton
            onClick={handleButtonClick}
            variant="contained"
            sx={{
              // m: '20px',
              mt: '20px',
              width: '342px',
              height: '56px',
              maxWidth: '100%',
              display: 'flex', // Add this style to make icon and text align horizontally
              alignItems: 'center', // Vertically center the icon and text
            }}
            color="primary"
            loading={paymentConfirmationLoading}
          >
            {t('START_FOOD_SELECTION')}
          </LoadingButton>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center', // Horizontally center
              alignItems: 'center', // Vertically center
            }}
          >
            <LoadingButton
              onClick={backToHome}
              variant="text"
              sx={{
                color: '#556885',
                mt: '12px',
                mb: '80px',
                width: '342px',
                height: '56px',
                maxWidth: '100%',
                display: 'flex', // Add this style to make icon and text align horizontally
                alignItems: 'center', // Vertically center the icon and text
                borderRadius: '48px',
              }}
            >
              {t('SELECT_LATER')}
            </LoadingButton>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default PaymentSuccess
