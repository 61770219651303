import {
  AppBar,
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  ThemeOptions,
  ThemeProvider,
  Toolbar,
  Typography,
  createTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { PropsWithChildren, ReactElement, useEffect, useState } from "react";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { ReactComponent as BackArIcon } from "../assets/icons/back_ar.svg";
import { ReactComponent as BackEnIcon } from "../assets/icons/back_en.svg";
import { ReactComponent as MenuIcon } from "../assets/icons/menu_icon.svg";
import { ReactComponent as SidebarContactWhatsapp } from "../assets/icons/sidebar_contact_whatsapp.svg";
import { ReactComponent as SidebarContactWhatsappGray } from "../assets/icons/whatsapp_icon_gray.svg";

import { ReactComponent as SidebarDirectionLeft } from "../assets/icons/sidebar_direction_left.svg";
import { ReactComponent as SidebarLanguageEarth } from "../assets/icons/sidebar_language_earth.svg";
import { ReactComponent as SidebarLogout } from "../assets/icons/sidebar_logout.svg";
import { ReactComponent as SidebarProfileUser } from "../assets/icons/sidebar_profile_user.svg";
import { ReactComponent as SidebarSubscriptionBox } from "../assets/icons/sidebar_subscription_box.svg";
import { ReactComponent as SidebarTermsLock } from "../assets/icons/sidebar_terms_lock.svg";
import { ReactComponent as LogoWithTextAr } from "../assets/logos/eshterakaat_logo_with_text_ar.svg";
import { ReactComponent as LogoWithTextEn } from "../assets/logos/eshterakaat_logo_with_text_en.svg";
import { ReactComponent as EshterakatLogoWithTextAr } from "../assets/logos/eshterakat_arabic.svg";
import { ReactComponent as EshterakatLogoWithTextEn } from "../assets/logos/eshterakat_english.svg";
import { ReactComponent as PlaceHolderEshterakatLogo } from "../assets/logos/eshterakat_logo.svg";
import SettingsIcon from '@mui/icons-material/Settings'


import * as amplitude from "@amplitude/analytics-browser";
import { useIsAuthenticated } from "../hooks";
import { useUpdateDeepLink } from "../hooks/useUpdateDeepLink";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { cleanUserData } from "../store/slices/accounts";
import { cleanFoodSelectionData } from "../store/slices/foodselection";
import { newTheme } from "../theme/new-theme";
import { localStorageGet } from "../utils";
import getContactRestaurantLink from "../utils/getContactRestaurantLink";
import getContactUsLink from "../utils/getContactUsLink";

// import sidebar_logout from '../../assets/icons/sidebar_logout.svg'

export const Layout = ({
  title,
  children,
  restaurantLogo,
  isLoading,
  footer,
  subTitle,
}: PropsWithChildren<{
  title: string;
  subTitle?: string;
  restaurantLogo?: string;
  isLoading?: boolean;
  footer?: ReactElement;
}>) => {
  const { userData } = useAppSelector((state) => state.accounts);
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useIsAuthenticated();

  const { slug, planID } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isRoot = location.pathname === "/";

  useUpdateDeepLink();

  const isArabic = i18n.language === "ar";
  const dir = isArabic ? "rtl" : "ltr";
  document.dir = dir;

  const handleRedirectToHome = () => {
    navigate("/");
  };

  const [sideBarVisible, setSideBarVisible] = useState(false);

  const isSubscribed =
    userData?.customer?.active_subscriptions?.length ?? 0 > 0 ? true : false;

  let message = t("CONTACT_US_WHATSAPP_VISITOR");
  if (isAuthenticated) message = t("CONTACT_US_WHATSAPP_NON_SUBSCRIBED_USER");
  if (isSubscribed) message = t("CONTACT_US_WHATSAPP_SUBSCRIBED_USER");
  const whatsappAPIURL = getContactUsLink({ message });

  const handleOpenSideBar = () => {
    setSideBarVisible(true);
  };

  const handleCloseSideBar = () => {
    setSideBarVisible(false);
  };

  const BackIcon = isArabic ? <BackArIcon /> : <BackEnIcon />;

  let theme: ThemeOptions = { ...newTheme, direction: dir };

  const cacheRtl = createCache({
    key: isArabic ? "muirtl" : "muiltr",
    stylisPlugins: isArabic ? [prefixer, rtlPlugin] : [prefixer],
  });

  const handleArrowBackClick = () => {
    // window.history.back()
    const otherURLS = ["/auth/login", "/auth/register", "/food-selection"];
    const currentPath = window.location.pathname;

    // Default values if a slug or planID is not provided
    let _slug = slug;
    let _planID = planID;

    const checkoutData = localStorageGet("checkoutData");
    if (checkoutData) {
      const { restaurantSlug, planID: planIDInCheckout } = checkoutData;
      // If the user is on the "/checkout" page, and user presses back button
      if (currentPath == "/checkout") {
        _slug = slug || restaurantSlug;
        _planID = planID || planIDInCheckout;
      }
    }

    // Checkout data is stored in localStorage when the user clicks on the "Subscribe" button

    if (_slug && _planID) {
      if (currentPath == "/checkout") {
        // The user is on the "/checkout" page.
        navigate(`/restaurants/${_slug}/${_planID}`);
      } else {
        // The user is on the "/restaurants/:slug/:planID" page.
        // window.location.href = `/restaurants/${slug}`
        navigate(`/restaurants/${_slug}`);
      }
    } else if (_slug) {
      // The user is on the "/restaurants/:slug" page.
      navigate("/");
    } else if (otherURLS.includes(currentPath)) {
      // The user is on the home page. or any other page.
      navigate("/");
    } else {
      window.history.back();
    }
  };

  const restaurantContactValues = userData?.customer?.active_subscriptions?.map(
    (item) => {
      return {
        restaurantName: item.restaurant_name,
        restaurantNameArabic: item.restaurant_name_arabic,
        planTitle: item.plan_title,
        planTitleArabic: item.plan_title_arabic,
        contactNumber: item.contact_number,
      };
    }
  );

  const uniqueRestaurantContactValues = restaurantContactValues?.filter(
    (item, index, self) => {
      return (
        index ===
        self.findIndex(
          (t) =>
            t.restaurantName === item.restaurantName &&
            t.contactNumber === item.contactNumber
        )
      );
    }
  );

  const onClick = (lng: string) => {
    i18n.changeLanguage(lng === "ar" ? "en" : "ar");
    document.body.dir = i18n.dir();
    const updateTheme = createTheme({ ...theme, direction: i18n.dir() });
    theme = updateTheme;
  };

  const handleLogout = () => {
    dispatch(cleanFoodSelectionData());
    dispatch(cleanUserData());
    navigate("/");
  };

  const handleClick = (e: any) => {
    handleCloseSideBar();

    if (e.path) {
      if (e.externalLink) {
        if (e.path === "/contact-us" || e.path === "/contact-restaurant") {
          window.open(e.href, "_blank");
          return;
        }
      } else {
        navigate(e.path);
      }
    } else {
      if (e.key === "language") {
        console.log("changing lang");
        onClick(i18n.language);
      }
    }
  };

  const sidebarItems = [
    ...(!isAuthenticated
      ? []
      : [
          {
            group: "main_menu",
            title: "MAIN_MENUS",
            items: [
              {
                title: "Profile",
                path: "/profile",
                icon: <SidebarProfileUser />,
                externalLink: false,
                isTranslated: false,
              },
              {
                title: "Subscriptions",
                path: "/my-subscriptions",
                icon: <SidebarSubscriptionBox />,
                externalLink: false,
                isTranslated: false,
              },
              ...(uniqueRestaurantContactValues || []).map((item) => {
                const restaurant_name = isArabic
                  ? item.restaurantNameArabic
                  : item.restaurantName;
                const title = `${restaurant_name}`;
                return {
                  title: title,
                  isTranslated: true,
                  icon: <SidebarContactWhatsapp />,
                  path: "/contact-restaurant",
                  externalLink: true,
                  href: getContactRestaurantLink({
                    isArabic,
                    restaurantName: restaurant_name,
                    planTitle: isArabic ? item.planTitleArabic : item.planTitle,
                    contactNumber: item.contactNumber,
                  }),
                };
              }),
            ],
          },
        ]),
    {
      group: "app_settings",
      title: "APP_SETTINGS",
      items: [
        {
          title: "LANGUAGE",
          path: "",
          key: "language",
          icon: <SidebarLanguageEarth />,
          externalLink: false,
          isTranslated: false,
        },
     
        {
          title: "Terms and Conditions",
          path: "/terms-policy",
          icon: <SidebarTermsLock />,
          externalLink: false,
          isTranslated: false,
        },
      ],
    },
  ];

  useEffect(() => {
    amplitude.setUserId(userData?.phone_number);
  }, [userData]);

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          height={"100dvh"}
          sx={(theme) => ({
            background: theme.palette.primary.main,
          })}
        >
          <Box display="flex">
            <AppBar position="fixed" elevation={0}>
              <Toolbar sx={{ height: "83px" }}>
                <Box
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <IconButton
                    size="small"
                    onClick={!isRoot ? handleArrowBackClick : handleOpenSideBar}
                    sx={{
                      backgroundColor: "transparent",
                      borderRadius: "10px",
                      padding: "0px",
                    }}
                  >
                    {!isRoot ? (
                      BackIcon
                    ) : (
                      <MenuIcon
                        style={{
                          transform:
                            i18n.dir() === "ltr" ? "scaleX(-1)" : "scaleX(1)",
                        }}
                      />
                    )}
                  </IconButton>

                  {!isRoot && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography
                        variant="h1"
                        component="div"
                        sx={{
                          lineHeight: "normal",
                          fontStyle: "normal",
                        }}
                      >
                        {title}
                      </Typography>
                      <Typography fontWeight="400" fontSize="14px">
                        {subTitle}
                      </Typography>
                    </Box>
                  )}

                  {isRoot ? (
                    <Button
                      disableElevation
                      disableRipple
                      onClick={handleRedirectToHome}
                    >
                      {isArabic ? (
                        <LogoWithTextAr title="اشتراكات" />
                      ) : (
                        <LogoWithTextEn title="eshterakat" />
                      )}
                    </Button>
                  ) : restaurantLogo ? (
                    <CardMedia
                      onClick={handleRedirectToHome}
                      component="img"
                      alt="eshterakat"
                      image={restaurantLogo}
                      sx={{
                        width: "40px",
                        height: "40px",
                        border: "1px solid rgba(255, 255, 255)",
                        borderRadius: "10px",
                      }}
                    />
                  ) : (
                    <Button
                      sx={{
                        width: "40px",
                        height: "40px",
                      }}
                      disableElevation
                      disableRipple
                      onClick={handleRedirectToHome}
                    >
                      <PlaceHolderEshterakatLogo title="eshterakat" />
                    </Button>
                  )}
                </Box>
              </Toolbar>
              <Divider
                sx={(theme) => ({
                  backgroundColor: theme.palette.background.default,
                  border: "none",
                  height: "15px",
                  borderRadius:
                    "var(--3, 24px) var(--3, 24px) var(--none, 0px) var(--none, 0px)",
                })}
              ></Divider>
            </AppBar>
          </Box>
          <Box
            mt="76px"
            flexGrow={1}
            display="flex"
            flexDirection={"column"}
            sx={(theme) => ({
              background: theme.palette.background.default,
              padding: "24px 24px 28px 24px",
              overflowY: "auto",
              overflowX: "hidden",
              overscrollBehavior: "contain",
              height: "calc(100dvh - 98px)",
            })}
          >
            {isLoading ? (
              <CircularProgress sx={{ marginX: "auto", mt: "25px" }} />
            ) : (
              children
            )}
          </Box>
        </Box>

        {!isLoading && (
          <Box
            sx={{
              position: "fixed",
              bottom: "0",
              width: "100%",
              zIndex: 1000,
            }}
          >
            {footer}
          </Box>
        )}
        <Drawer
          sx={{
            width: "256px",
            minHeight: "auto",
          }}
          open={sideBarVisible}
          anchor="left"
          onClose={handleCloseSideBar}
          PaperProps={{
            style:
              i18n.dir() == "ltr"
                ? { right: "unset", left: 0 }
                : { left: "unset", right: 0 },
          }}
        >
          <Box
            role="presentation"
            onClick={handleCloseSideBar}
            sx={(theme) => ({
              backgroundColor: theme.palette.background.default,
              width: 256,
              height: "max-content",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              flex: 1,
            })}
          >
            <Box
              sx={{
                width: "208px",
                height: "100%",
                overflow: "hidden",
                marginX: "24px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row-reverse",
                  marginY: "8px",
                  marginX: "0px",
                  paddingX: "0px",
                  mt: "20px",
                }}
              >
                {isArabic ? (
                  <EshterakatLogoWithTextAr />
                ) : (
                  <EshterakatLogoWithTextEn />
                )}
              </Box>
              <Stack
                sx={{
                  height: "auto",
                  justifyContent: "space-between",
                  mb: "20px",
                }}
              >
                <List
                  component="nav"
                  dir={i18n.dir()}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "8px",
                  }}
                >
                  {!isAuthenticated && (
                    <ListItemButton
                      sx={{
                        bgcolor: "#D8EEE9",
                        display: "flex",
                        justifyContent: "space-between",
                        mt: "20px",
                        borderRadius: "var(--3, 24px)",
                        color: "#10A67B",
                      }}
                      onClick={() => navigate("/auth/login")}
                    >
                      <ListItemText primary={t("LOGIN")} />
                      <ListItemIcon>
                        <SidebarDirectionLeft
                          style={{
                            transform:
                              i18n.dir() === "ltr" ? "scaleX(-1)" : "scaleX(1)",
                          }}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  )}
                  {sidebarItems.map((item) => {
                    return (
                      <Box key={item.title}>
                        <Typography
                          variant="h3"
                          sx={{
                            lineHeight: "165%",
                            letterSpacing: "0.2px",
                            mt: "24px",
                            mb: "16px",
                          }}
                        >
                          {t(item.title)}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "8px",
                          }}
                        >
                          {item.items.map((subItem) => {
                            return (
                              <ListItemButton
                                key={subItem.title}
                                onClick={() => {
                                  handleClick(subItem);
                                }}
                              >
                                <ListItemIcon>{subItem.icon}</ListItemIcon>
                                <ListItemText
                                  sx={{ color: "#7587A1", width: "100%" }}
                                  primary={
                                    subItem?.isTranslated
                                      ? subItem.title
                                      : t(
                                          String(
                                            subItem.title?.toLocaleUpperCase()
                                          ).replace(/\s+/g, "_")
                                        )
                                  }
                                />
                              </ListItemButton>
                            );
                          })}
                        </Box>
                      </Box>
                    );
                  })}
                </List>
              </Stack>
              {isAuthenticated ? (
                <Stack
                  direction="row"
                  flexDirection="row"
                  width="100%"
                  paddingX={0}
                  paddingTop="6px"
                  marginX={0}
                  justifyContent="space-between"
                  sx={{
                    position: "absolute",
                    bottom: "30px", // Move it to the bottom
                  }}
                >
                   <IconButton
                    onClick={() => navigate("/settings")}
                    sx={{  p: 0, backgroundColor: "transparent" }}
                    size="medium"
                  >
                    <SettingsIcon style={{color: '#7587A1', width: "27px", height: "27px" }} />
                  </IconButton>
                  <IconButton
                    onClick={() =>  handleClick({path: "/contact-us", externalLink: true, href: whatsappAPIURL}) }
                    sx={{  p: 0, backgroundColor: "transparent" }}
                    size="medium"
                  >
                   <SidebarContactWhatsappGray style={{width: "24px", height: "24px" }} />
  
                    </IconButton>

                  <IconButton
                    onClick={handleLogout}
                    sx={{  p: 0, backgroundColor: "transparent" }}
                    size="medium"
                  >
                    <SidebarLogout
                      style={{
                        width: "26px", height: "26px" ,
                        backgroundColor: "transparent",
                        transform:
                          i18n.dir() === "rtl" ? "scaleX(-1)" : "scaleX(1)",
                      }}
                    />
                  </IconButton>
                </Stack>
              ):
              
              (
                <Stack
                  direction="row"
                  flexDirection="row"
                  width="100%"
                  paddingX={0}
                  marginX={0}
                  justifyContent="center"
                  sx={{
                    position: "absolute",
                    bottom: "30px", // Move it to the bottom
                  }}
                >

                  <IconButton
                    onClick={() =>  handleClick({path: "/contact-us", externalLink: true, href: whatsappAPIURL}) }
                    sx={{  p: 0, backgroundColor: "transparent" }}
                    size="medium"
                  >
                   <SidebarContactWhatsappGray style={{width: "24px", height: "24px" }} />
  
                  </IconButton>



                </Stack>)}{" "}
            </Box>
          </Box>
        </Drawer>
      </ThemeProvider>
    </CacheProvider>
  );
};
